export const PaymentSubscriptionStatus = [
  { title: 'Active', value: 'active' },
  { title: 'Inactive', value: 'inactive' },
  { title: 'Canceled', value: 'canceled' },
  { title: 'Paused', value: 'paused' },
  { title: '3DSecure started', value: 'enrolled_3ds' },
  { title: 'Expired', value: 'expired' },
];

export const PaymentSubscriptionStatusEnum  = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
  PAUSED: 'paused',
  ENROLLED_3DS: 'enrolled_3ds',
  EXPIRED: 'expired',
};
