<template>
  <v-timeline dense class="timeline-custom-dense timeline-custom-dots">
    <v-timeline-item v-for="(log, key) in logs" :key="key" :color="log.status" small>
      <div class="d-flex justify-space-between align-center flex-wrap">
        <h4 class="font-weight-medium me-1">
          {{ log.title }}
        </h4>
      </div>
      <p class="mb-0 outcome-overview">{{ log.createdAt | moment('YYYY-MM-DD HH:mm:ss') }} {{ log.message }}</p>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: {
    logs: [],
  }
};
</script>
