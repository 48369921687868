<template>
  <v-container>
    <v-card class="mb-7">
      <v-card-title>
        <div class="mb-2 mb-sm-0 w-full">
          <v-icon @click="$router.back()">{{ icons.mdiArrowLeftBoldCircleOutline }}</v-icon>
          Payment Subscription View
        </div>
      </v-card-title>
    </v-card>
    <v-row>
      <v-col cols="12" md="9">
        <v-card :loading="loading" class="mb-5" id="subscription-details">
          <v-card-title>
            <span class="mr-3">Subscription Details</span>
            <v-chip
              v-if="paymentData"
              small
              :color="resolvePaymentSubscriptionStatusVariant(paymentData.status)"
              :class="`${resolvePaymentSubscriptionStatusVariant(paymentData.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ resolvePaymentSubscriptionStatusName(paymentData.status) }}
            </v-chip>
          </v-card-title>
          <v-card-text v-if="paymentData">
            <v-row>
              <v-col cols="12" md="6">
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Subscription Token</v-list-item-title>
                      <v-list-item-subtitle class="subscription-token">
                        <span>{{ paymentData.subscriptionToken }}</span>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="copyValue(paymentData.subscriptionToken)"
                              class="pl-3 pr-2"
                            >
                              {{ icons.mdiContentCopy }}
                            </v-icon>
                          </template>
                          <span>Copy</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Started</v-list-item-title>
                      <v-list-item-subtitle class="created-at">{{ paymentData.createdAt | moment(`${getDateFormat(paymentData.createdAt)}`) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Acquirer</v-list-item-title>
                      <v-list-item-subtitle class="payment-provider">{{ paymentData.paymentProvider }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Expires</v-list-item-title>
                      <v-list-item-subtitle class="expires-at">{{ paymentData.expiresAt | moment(`${getDateFormat(paymentData.expiresAt)}`) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Frequency</v-list-item-title>
                      <v-list-item-subtitle class="frequency">{{ paymentData.frequency }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Cancelled</v-list-item-title>
                      <v-list-item-subtitle class="cancelled-at">{{ paymentData.cancelledAt | moment(`${getDateFormat(paymentData.cancelledAt)}`) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card :loading="loading" id="subscription-payments">
          <v-card-title>Subscription Payments</v-card-title>
          <v-card-text v-if="paymentData">
            <PaymentEventsList :subscription-id="router.currentRoute.params.id" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card :loading="loading" id="subscription-logs">
          <v-card-title>Subscription Logs</v-card-title>
          <v-card-text v-if="paymentData">
            <payment-log-view :logs="paymentData.logs" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref } from '@vue/composition-api';
import { mdiArrowLeftBoldCircleOutline, mdiContentCopy, mdiDotsVertical } from '@mdi/js';
import { getDateFormat } from '@core/utils/filter';
import PaymentEventsList from '@/views/payments/payment-events/PaymentEventsList.vue';
import PaymentLogView from '@/views/payments/_partials/PaymentLogView';
import usePaymentSubscriptions
  from '@/views/payments/payment-subscriptions/usePaymentSubscriptions';
import { copyValue } from '@core/utils';

export default {
  components: { PaymentEventsList, PaymentLogView },
  setup() {
    const {
      resolvePaymentSubscriptionStatusVariant,
      resolvePaymentSubscriptionStatusName,
    } = usePaymentSubscriptions();

    const paymentData = ref(null);
    const loading = ref(true);

    store
      .dispatch('paymentSubscription/fetchPaymentSubscriptionById', router.currentRoute.params.id)
      .then(response => {
        paymentData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          paymentData.value = {};
        }
      })
      .finally(() => {
        loading.value = false;
      });

    return {
      paymentData,
      loading,
      icons: {
        mdiArrowLeftBoldCircleOutline,
        mdiContentCopy,
        mdiDotsVertical,
      },
      router,
      getDateFormat,
      copyValue,
      resolvePaymentSubscriptionStatusVariant,
      resolvePaymentSubscriptionStatusName,
    };
  },
};
</script>
