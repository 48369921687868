import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import { isAdmin } from '@/services/jwt.service';
import {
  PaymentSubscriptionStatus,
  PaymentSubscriptionStatusEnum
} from '@/enums/payments/payment-subscription-status.enum';
import CardType from '@/enums/card-type.enum';
import moment from 'moment';
import { PaymentProviderEnum } from '@/enums/payments/payment-provider.enum';

export default function usePaymentSubscriptions(toFetchCompanies = true) {
  const paymentSubscriptionsListTable = ref([]);

  const tableColumns = [
    { text: 'ID', value: 'subscriptionToken', sortable: false },
    { text: 'Merchant', value: 'merchant', sortable: false },
    { text: 'Acquirer', value: 'paymentProvider', sortable: false },
    { text: 'Status', value: 'status', sortable: false },
    { text: 'Created At', value: 'createdAt', sortable: false },
    { text: 'Expires At', value: 'expiresAt', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const searchQuery = ref('');
  const statusFilter = ref(null);
  const status = ref(null);
  const companyFilter = ref(null);
  const paymentSubscriptionDateFilter = ref(null);
  const paymentSubscriptionDateModal = ref(null);
  const companies = ref([]);
  const totalPaymentSubscriptionsListTable = ref(0);
  const loading = ref(false);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });

  const newDate = new Date();
  const presetsForPaymentSubscriptionDatePicker = [
    {
      title: '3 Days',
      value: [moment(newDate).subtract(2, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '7 Days',
      value: [moment(newDate).subtract(6, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '30 Days',
      value: [moment(new Date()).subtract(29, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'This Month',
      value: [moment(newDate).startOf('month').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Last Month',
      value: [
        moment(newDate).subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
    {
      title: 'Last 3 Months',
      value: [
        moment(newDate).subtract(3, 'month').startOf('month').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
      ],
    },
  ];

  const paymentSubscriptionStatus = PaymentSubscriptionStatus;
  const paymentSubscriptionStatusEnum = PaymentSubscriptionStatusEnum;
  const paymentSubscriptionServiceEnum = PaymentProviderEnum;

  const resolvePaymentSubscriptionStatusVariant = paymentSubscriptionStatus => {
    switch (paymentSubscriptionStatus) {
      case PaymentSubscriptionStatusEnum.ACTIVE:
        return 'success';
      case PaymentSubscriptionStatusEnum.INACTIVE:
      case PaymentSubscriptionStatusEnum.CANCELED:
      case PaymentSubscriptionStatusEnum.EXPIRED:
        return 'danger';
      default:
        return 'warning';
    }
  };

  const resolvePaymentSubscriptionStatusName = paymentSubscriptionStatus => {
    return PaymentSubscriptionStatus.find(status => status.value === paymentSubscriptionStatus).title;
  };

  // fetch data
  const fetchPaymentSubscriptions = () => {
    store
      .dispatch('paymentSubscription/fetchPaymentSubscriptions', {
        q: searchQuery.value,
        paymentSubscriptionDate: paymentSubscriptionDateFilter.value,
        status: statusFilter.value,
        companyId: companyFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        itemsPerPage: options.value.itemsPerPage,
      })
      .then(response => {
        paymentSubscriptionsListTable.value = response.data.data;
        totalPaymentSubscriptionsListTable.value = response.data.totalCount;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payment Subscriptions',
          text: 'Cannot fetch payment subscriptions',
        });
      });
  };

  // fetch companies
  const fetchCompanies = () => {
    store
      .dispatch('company/fetchCompanies')
      .then(response => {
        companies.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Payment Subscriptions',
          text: 'Cannot fetch companies',
        });
      });
  };

  watch([options], () => {
    loading.value = true;
    fetchPaymentSubscriptions();
  });

  watch([searchQuery, statusFilter, companyFilter, paymentSubscriptionDateFilter], () => {
    options.value.page = 1;
    loading.value = true;
    fetchPaymentSubscriptions();
  });

  if (toFetchCompanies && isAdmin()) {
    fetchCompanies();
  }

  const selectedPaymentSubscriptionPresetDate = preset => {
    return (paymentSubscriptionDateFilter.value = preset);
  };

  const cardTypeOptions = CardType;

  return {
    status,
    paymentSubscriptionsListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    companyFilter,
    cardTypeOptions,
    paymentSubscriptionDateFilter,
    paymentSubscriptionDateModal,
    presetsForPaymentSubscriptionDatePicker,
    companies,
    totalPaymentSubscriptionsListTable,
    loading,
    options,
    paymentSubscriptionStatus,
    paymentSubscriptionStatusEnum,
    resolvePaymentSubscriptionStatusVariant,
    resolvePaymentSubscriptionStatusName,
    fetchPaymentSubscriptions,
    selectedPaymentSubscriptionPresetDate,
    paymentSubscriptionServiceEnum,
  };
}
